<template>
	<div>
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'app',
		components: {

		}
	}
</script>

<style>
	body,
	html {
		padding: 0;
		margin: 0;
	}
</style>