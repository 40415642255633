<template>
	<div class="container">

		<!-- <div class="videoImage" ref="faceBox">
			<el-button type="primary" @click="useCamera">打开摄像头</el-button>
			<el-button type="plain" @click="photoShoot">拍照</el-button>
			<el-alert :title="httpsAlert" type="info" :closable="false" v-show="httpsAlert !== ''">
			</el-alert>
			<video ref="video" style="display: none;"></video>
			<div class="canvas">
				<canvas ref="canvas" v-show="videoShow"></canvas>
			</div>
			<img ref="image" :src="picture" alt="" v-show="pictureShow">
		</div> -->
		<div>
			<div class="login">
			</div>
			<!--登录中间块-->
			<div class="login-mid">
				<div class="login-mid-top">
					<div class="shadow-top-left"></div>
					<div class="shadow-top-right"></div>
				</div>
				<div class="login-mid-mid">

					<!--捕获人脸区域-->
					<div class="videoCamera-canvasCamera">
						<video id="videoCamera" ref="video" :width="videoWidth" :height="videoHeight" autoplay></video>
						<canvas style="display: none" ref="canvas" id="canvasCamera" :width="videoWidth"
							:height="videoHeight"></canvas>
						<!-- <img ref="image" :src="picture" alt=""> -->
						<!--人脸特效区域-->
						<div v-if="faceImgState" class="face-special-effects-2"></div>
						<div v-else class="face-special-effects"></div>
					</div>

					<!--按钮区域-->
					<div class="face-btn">

						<audio ref="audioPlayer" muted>
							<source type="audio/wav">
						</audio>
						<button @click="useCamera()">{{faceImgState?'正在识别中...':'人脸识别'}}</button>
					</div>

					<!--消息区域-->
					<div class="msg">
						<div class="welcome">Welcome to face recognition</div>
					</div>

				</div>
				<div class="login-mid-bot">
					<div class="shadow-bot-left"></div>
					<div class="shadow-bot-right"></div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import $camera from '../../camera/index.js'
	import * as faceApi from 'face-api.js'
	// import audioSrc from '@/assets/daoxiang.mp3'
	export default {
		name: 'Recognize',
		data() {
			return {
				videoShow: false,
				pictureShow: false,
				// 图片地址
				picture: '',
				// 用于视频识别的节点
				canvas: null,
				video: null,
				image: null,
				timeout: 0,
				// 模型识别的条件
				options: '',
				// 提示控制
				noOne: '',
				moreThanOne: '',
				// 不是通过Https访问提示
				httpsAlert: '',
				videoWidth: 200,
				videoHeight: 200,
				faceImgState: false,
				audioSrc: '',
				throttleTimeout: null
			}
		},
		mounted() {
			// 初始化
			this.init()
		},
		beforeDestroy() {
			clearTimeout(this.timeout);
			this.$message.close();
		},
		methods: {
			afterExecution(e) {
				console.log(e);
				// console.log(this.$refs.audioPlayer.play());
				this.$refs.audioPlayer.src = e;
				this.$refs.audioPlayer.play()
			},
			async init() {
				await faceApi.nets.tinyFaceDetector.loadFromUri("/models");
				// await faceApi.loadFaceLandmarkModel("/models");
				// this.options = new faceApi.SsdMobilenetv1Options({
				// 	minConfidence: 0.3, // 0.1 ~ 0.9
				// });
				this.options = new faceApi.TinyFaceDetectorOptions({
					// inputSize: 320,
					scoreThreshold: 0.6
				})
				// 视频中识别使用的节点
				this.video = this.$refs.video
				this.canvas = this.$refs.canvas
				this.image = this.$refs.image
				this.useCamera()
			},
			/**
			 * 使用视频来成像摄像头
			 */
			useCamera() {
				this.videoShow = true
				this.pictureShow = false
				this.cameraOptions()
			},
			/**
			 * 使用摄像头
			 */
			cameraOptions() {
				let constraints = {
					video: {
						width: 100,
						height: 100
					}
				}
				// 如果不是通过loacalhost或者通过https访问会将报错捕获并提示
				try {
					let promise = navigator.mediaDevices.getUserMedia(constraints);
					promise.then((MediaStream) => {
						// console.log(MediaStream);
						// 返回参数
						this.video.srcObject = MediaStream;
						this.video.play();
						let face_token = localStorage.getItem("face_token");
						if (!face_token) {
							this.recognizeFace()
						}

					}).catch((error) => {
						console.log(error);
					});
				} catch (err) {
					this.httpsAlert = `您现在在使用非Https访问，
				             请先在chrome://flags/#unsafely-treat-insecure-origin-as-secure中修改配置,
				             添将当前链接${window.location.href}添加到列表,
				             并且将Insecure origins treated as secure修改为enabled,
				             修改完成后请重启浏览器后再次访问！`
				}
			},
			/**
			 * 人脸识别方法
			 * 通过canvas节点识别
			 * 节点对象执行递归识别绘制
			 */
			async recognizeFace() {
				if (this.video.paused) return clearTimeout(this.timeout);
				this.canvas.getContext('2d', {
					willReadFrequently: true
				}).drawImage(this.video, 0, 0, 200, 200);
				const results = await faceApi.detectAllFaces(this.canvas, this.options);
				if (results.length === 0) {
					if (this.moreThanOne !== '') {
            if(this.moreThanOne) this.moreThanOne.close()
						this.moreThanOne = ''
					}
					if (this.noOne === '') {
						this.noOne = this.$message({
							message: '未识别到人物特征',
							type: 'warning',
							duration: 0
						});
						// 防抖
						// if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
						// this.debounceTimeout = setTimeout(() => {
						// 	// 在这里添加实际要调用的函数及其参数
						// 	this.noOne.close()
						// 	this.throttleTimeout = null
						// }, 3000)


					}
				} else if (results.length > 1) {
					if (this.noOne !== '') {
            if(this.noOne) this.noOne.close()
						this.noOne = ''
					}
					if (this.moreThanOne === '') {
						this.moreThanOne = this.$message({
							message: '检测到镜头中有多个人',
							type: 'warning',
							duration: 0
						});
					}
				} else {
					if (this.noOne !== '') {
            if(this.noOne) this.noOne.close()
						this.noOne = ''
						this.faceImgState = true
						this.photoShoot()
					}
					if (this.moreThanOne !== '') {
            if(this.moreThanOne) this.moreThanOne.close()
						this.moreThanOne = ''
					}
				}
				// 通过canvas显示video信息
				this.timeout = setTimeout(() => {
					return this.recognizeFace()
				});
			},
			/**
			 * 拍照上传
			 */
			async photoShoot() {
				// 拿到图片的base64
				let canvas = this.canvas.toDataURL("image/png");
				// 拍照以后将video隐藏
				this.videoShow = false
				this.pictureShow = true
				// 停止摄像头成像
				this.video.srcObject.getTracks()[0].stop()
				this.video.pause()
				// this.faceVef(canvas)
				if (canvas) {
					// 拍照将base64转为file流文件
					let blob = this.dataURLtoBlob(canvas);
					// console.log(blob)
					let file = this.blobToFile(blob, "imgName");
					// console.info(file);
					// 将blob图片转化路径图片
					let image = window.URL.createObjectURL(file)
					this.picture = image
					// 将拍照后的图片发送给后端
					let formData = new FormData()
					formData.append('file', this.picture)
					let imageBase = canvas
					this.$http.post("/face/vef", {
						imageBase
					}).then(res => {
						console.log("res = " + res);
						this.faceImgState = false
						// 跳转首页
						if (res.data.code === 200) {
							this.audioSrc = res.data.data.path
							this.afterExecution(res.data.data.path);
							// 关闭摄像头
							localStorage.setItem("face_token", res.data.token);
							localStorage.setItem("username", res.data.name);
							this.$message.success(res.data.msg)
							console.log(res);
							setTimeout(() => {

								this.useCamera()
								this.$router.push({
									name: 'login',
									params: {
										fid: res.data.data.adminStatus,
										name: res.data.name
									}
								})

							}, 3000);
						}else if (res.data.code === 201) {
							// 失败再次弹出错误,再次调用相机
							this.useCamera()
							this.$message.success(res.data.msg)
						}else {
              this.$message.error(res.data.msg)
            }
					}, onerror => {
						// 识别失败重新调用识别
						this.faceImgState = false
						this.useCamera()
					})
				} else {
					console.log('canvas生成失败')
				}
			},
			/**
			 * 将图片转为blob格式
			 * dataurl 拿到的base64的数据
			 */
			dataURLtoBlob(dataurl) {
				let arr = dataurl.split(','),
					mime = arr[0].match(/:(.*?);/)[1],
					bstr = atob(arr[1]),
					n = bstr.length,
					u8arr = new Uint8Array(n);
				while (n--) {
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new Blob([u8arr], {
					type: mime
				});
			},
			/**
			 * 生成文件信息
			 * theBlob 文件
			 * fileName 文件名字
			 */
			blobToFile(theBlob, fileName) {
				theBlob.lastModifiedDate = new Date().toLocaleDateString();
				theBlob.name = fileName;
				return theBlob;
			},

		}
	}
</script>
<style>
	@import "./index.css";
</style>