<template>
	<div>
		<div class="login">
		</div>
		<!--登录中间块-->
		<div class="login-mid">
			<div class="login-mid-top">
				<div class="shadow-top-left"></div>
				<div class="shadow-top-right"></div>
			</div>
			<div class="login-mid-mid">

				<!--捕获人脸区域-->
				<div class="videoCamera-canvasCamera">
					<video id="videoCamera" ref="video" :width="videoWidth" :height="videoHeight" autoplay></video>
					<canvas style="display: none" ref="canvas" id="canvasCamera" :width="videoWidth"
						:height="videoHeight"></canvas>

					<!--人脸特效区域-->
					<div v-if="faceImgState" class="face-special-effects-2"></div>
					<div v-else class="face-special-effects"></div>
				</div>

				<!--按钮区域-->
				<div class="face-btn">
					<button @click="updata()">{{faceImgState?'正在识别中...':'手势识别'}}</button>
					<!-- <div class="logbtn" @click="btnChange()" v-else>
						点我唤醒相机
					</div> -->
				</div>

				<!--消息区域-->
				<div class="msg">
					<!-- <img :src="xyyy" alt=""> -->
					<!-- <div class="server-msg">{{msg}}</div> -->
					<div class="welcome">Welcome to gesture recognition</div>
				</div>

			</div>

			<div class="login-mid-bot">
				<div class="shadow-bot-left"></div>
				<div class="shadow-bot-right"></div>
			</div>
		</div>

	</div>

</template>
<script>
	import $camera from '../../camera/index.js'
	export default {
		data() {
			return {
				videoWidth: 200,
				videoHeight: 200,
				msg: '',
				faceImgState: false,
				faceOption: {},
				time: '', //定时器
				counter: 0, //定时器计数
				throttleTimeout: null,
				noOne: '',
				isnum: 0, //确定判断照片里是否有人手
			};
		},

		mounted() {
			// 调用摄像头
			this.sxt()
		},
		methods: {
			// 相机唤醒按钮
			updata() {
				// 定时器计数重置为0
				this.counter = 0
				// 显示视频流
				// 掉相机
				this.sxt()
			},
			// 启动相机
			sxt() {
				this.faceOption = $camera.getCamera({
					videoWidth: 200,
					videoHeight: 200,
					thisCancas: null,
					thisContext: null,
					thisVideo: null,
					canvasId: 'canvasCamera',
					videoId: 'videoCamera'
				});
				// 启动定时器
				this.interval()
			},
			// 关闭视频流,关闭相机
			close() {
				// 关闭相机
				this.faceOption.thisVideo.srcObject.getTracks()[0].stop();
			},
			interval() {
				// 每1.5秒执行一次
				this.time = setInterval(() => {
					// 手势比对失败10次,清除token,跳人脸
					if (this.counter >= 10) {
						// 未识别到关相机
						this.close()
						this.$router.push('/')
						clearInterval(this.time); // 终止定时器
					} else {
						// 掉后台接口
						if (this.isnum == 0) {
							this.faceVef()
						}
					}
				}, 1000);
			},
			// 调用后台接口
			faceVef() {
				this.isnum = 0
				// 开始绘制图片
				let imageBase = $camera.draw(this.faceOption)
				if (this.faceImgState) {
					return
				}
				if (imageBase === '' || imageBase === null || imageBase === undefined) {
					this.$message.error("图片数据为空")

				} else {
					this.$http.post("/face/handPosture", {
						imageBase
					}).then(res => {
						if (res.data.code === 200) {
							if (res.data.data.outputs[0].handCount != 0) {
								this.faceImgState = true
								this.commit(imageBase)
								this.isnum = 1
								this.counter += 1; // 增加计数器
							} else {
								if (this.throttleTimeout) return

								this.throttleTimeout = setTimeout(() => {
									// 在这里添加实际要调用的函数及其参数
									this.noOne = this.$message.error('未识别人手')
									this.throttleTimeout = null
								}, 2000)
							}
						}
						if (res.data.code === 201) {

							this.$message.success(res.data.msg)
						}
					}, onerror => {

						this.faceImgState = false
					})
				}
			},
			// 上传图片到后端
			commit(imageBase) {

				setTimeout(() => {
					this.faceImgState = false
				}, 1500)
				// 如果识别到人手,计数器重新开始
				this.counter = 0
				this.$http.post("/face/recognizeHandGesture", {
					imageBase
				}).then(res => {
					this.close()
					clearInterval(this.time); // 终止定时器
					this.$message.success('识别成功,等待跳转')
          if(this.noOne) this.noOne.close()
					console.log(res);
					if (res.data.code == 200) {
						// bound.hello(this.$route.params.name)

						// bound.hello(this.$route.params.name)
						try {
							bound.hello(this.$route.params.name)
							console.dir("bound = " + bound)
						} catch (e) {
							console.log("e = " + e)
						}
						if (this.$route.params.fid === 1) {
							setTimeout(() => {
								// this.useCamera()
								let routeData = this.$router.resolve({
									path: '/home',
									query: {
										data: JSON.stringify(res),
										img: imageBase
									}
								})
								window.open(routeData.href, '_blank')

							}, 1500);
							this.$router.push('/')
							this.isnum = 0
						} else {
							setTimeout(() => {
								// this.useCamera()
								localStorage.clear()
								this.$router.push('/')
							}, 1500);
						}
					}
				}).catch(err => {
          console.log(err)
					this.isnum = 0
					if (err.includes('UnRecognizedGesture')) {
						this.$message.error('手势动作不正确')
            if(this.noOne) this.noOne.close()
					} else {
						this.$message.error('未识别手势')
            if(this.noOne) this.noOne.close()
					}
				})
			}
		},
		beforeDestroy() {
			clearInterval(this.time); // 终止定时器
			this.$message.close();
		}
	};
</script>
<style scoped>
	@import "./index.css";

	.logbtn {
		text-align: center;
		line-height: 50px;
		cursor: pointer;
		position: absolute;
		top: 155px;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		font-size: 15px;
		font-family: Arial;
		width: 140px;
		height: 50px;
		border-width: 1px;
		color: #3a8a9e;
		border-color: #d6bcd6;
		font-weight: bold;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		border-bottom-left-radius: 15px;
		border-bottom-right-radius: 15px;
		box-shadow: 3px 4px 0px 0px #899599;
		text-shadow: 0px 1px 0px #e1e2ed;
		background: linear-gradient(#ededed, #bab1ba);
	}

	.logbtn:hover {
		background: linear-gradient(#bab1ba, #ededed);
	}
</style>