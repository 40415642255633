import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login/index'
import desktop from '../components/desktop/index'
import XYY from '../views/login/xyy.vue'
Vue.use(VueRouter)

const routes = [{
		path: '/login',
		name: 'login',
		component: login
	},
	{
		path: '/',
		name: 'xyy',
		component: XYY
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('@/views/home/index'),
		redirect: '/desktop',
		children: [{
				path: '/desktop',
				name: 'desktop',
				component: desktop
			},
			{
				path: '/faceList',
				name: 'face',
				component: () => import('@/components/face/index')
			},
			{
				path: '/faceLog',
				name: 'log',
				component: () => import('@/components/faceLog/index')
			}
		]
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/about/about')
	},
	{
		path: '/hand',
		name: 'hand',
		component: () => import('@/views/about/Handtrack')
	}
]

const router = new VueRouter({
	routes
})


router.beforeEach((to, from, next) => {
	if (to.path === '/') return next()
	let face_token = localStorage.getItem("face_token");
	if (!face_token) return next("/")
	next()
})

export default router